import { FilterItemModel } from './../../../core/models/filter-item';
import { BusinessUnitType } from './../../../core/types/overview';
import { computed, onMounted, ref } from 'vue';
import clientsApi from '@/services/api/clients-api';

export function useBusinessUnitView(ids?: Array<number> | number) {
  const businessUnits = ref<Array<BusinessUnitType> | null>(null);

  onMounted(() => {
    if (!businessUnits.value) {
      fetchBusinessUnits();
    }
  });


  const businessUnitsFilters = computed(() => {
    const filters: Array<FilterItemModel> = [];

    businessUnits.value?.forEach(unit => {
      filters.push(new FilterItemModel(unit.id, unit.name));
    })

    return filters;
  })

  const currentBusinessUnits = computed(() => {
    if (!ids || !businessUnits.value) return [];
    if (Array.isArray(ids)) {
      console.log('yep');
      return businessUnits.value?.filter(unit => ids.includes(unit.id)); 
    }
    return businessUnits.value?.filter(unit => unit.id === ids);
  });

  const currentBusinessSubunits = computed(() => {
    if (!ids || !businessUnits.value) return [];
    if (Array.isArray(ids)) {
      return businessUnits.value
        .map(unit => unit.subunits)
        .flat()
        .filter(un => un && ids.includes(un.id));
    }
    return businessUnits.value
      .map(unit => unit.subunits)
      .flat()
      .filter(un => un && un.id === ids);
  });

  async function fetchBusinessUnits() {
    businessUnits.value = await clientsApi.fetchBusinessUnits();
  }

  function getBusinessUnitsNames(ids: Array<number>): Array<string> {
  
    const array: Array<string> = [];

    businessUnits.value?.forEach(unit => {
      if (ids.includes(unit.id)) {
        array.push(unit.name);
      }
      unit.subunits?.forEach(subunit => {
        if (ids.includes(subunit.id)) {
          array.push(subunit.name);
        }
      })
    })


    return array;
  }


  return {
    businessUnitsFilters,
    getBusinessUnitsNames,
    businessUnits,
    currentBusinessUnits,
    currentBusinessSubunits,
  }
}